import React from 'react';
import tw, {styled} from 'twin.macro';

import Layout from '@/components/layout';
import Hero from '@/components/pages/other/hero';
import Nav from "@/components/nav";
import Features from "@/components/features";
import Faq from "@/components/faq";
import Footer from "@/components/footer";
import LegalIll from "@/components/illustrations/legal";
import Seo from "@/components/seo";

const Wrapper = styled.div``;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	data: any;
	path: string;
}

const Legal = (props: Props) => {
	return (
		<Layout>
			<Seo {...props}/>
			<Wrapper>
				<div className="bg-primary-background">
					<Nav/>
					<Hero
						title={"Privacy, terms and refunds"}
						subtitle={`DR SOFT S.R.L - RO18669955, Bucharest, Romania`}
						indicator={"/legal"}
						illustration={<LegalIll
							height={"100%"}
							width={"100%"}
						/>}
					/>
				</div>

				<div className="bg-bg-background py-24">
					<div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
						<div className="bg-white shadow overflow-hidden sm:rounded-lg mx-auto" id={"refunds"}>
							<div className="px-4 py-5 border-b border-gray-200 sm:px-6">
								<h3 className="text-lg leading-6 font-bold text-gray-900">
									Refunds
								</h3>
								<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
									How you get your money back and in what situations
								</p>
							</div>
							<div className="px-4 py-5 sm:p-0">
								<div className="px-8 py-8 text-gray-600">
									<div>
										<b>We will</b> refund the full amount that you have been charged with
										in the following cases:

										<ul className={"mt-4 list-disc pl-10"}>
											<li>the installer encountered an error</li>
											<li>the installed service does not work</li>
											<li>
												the VM service partner had issues that prevented
												our install process from finishing
											</li>
											<li>
												an error was encountered during the process
												of sending you the config file via email
											</li>
										</ul>
									</div>

									<div className={"mt-4"}>
										<b>We will not</b> refund you if:

										<ul className={"mt-4 list-disc pl-10"}>
											<li>
												the service is up and running but you fail
												to connect because you're not able to use
												any of the client applications
											</li>
											<li>
												you have an operating system that prevents
												you from operating or installing any VPN client
											</li>
											<li>
												you fail to read and understand our English
												written material that guide you through the
												process of adding more clients
											</li>
											<li>
												you bring your server into a state of
												non-function or malfunction following
												your intervention and modification
												attempts
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8" id={"privacy"}>
						<div className="bg-white shadow overflow-hidden sm:rounded-lg mx-auto mt-20">
							<div className="px-4 py-5 border-b border-gray-200 sm:px-6">
								<h3 className="text-lg leading-6 font-bold text-gray-900">
									Privacy policy
								</h3>
								<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
									How we collect, handles and processes data of our customers and visitors
								</p>
							</div>
							<div className="px-4 py-5 sm:p-0">
								<dl>
									<div className="sm:grid sm:grid-cols-4 sm:gap-8 sm:px-6 sm:py-5">
										<dt className="text-sm leading-5 font-normal text-primary-500">
											We will not
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Store your personal information for any other reason
											but to be able to create and install your services or
											to send occasional communication messages but only if
											you opted-in for such a thing.
										</dd>

										<dt className="text-normal leading-5 font-normal text-primary-500">
											We will
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Periodically wipe our records following a successful
											install of our services and make sure no personal
											information is stored for longer than required.
										</dd>

										<dt className="text-normal leading-5 font-normal text-primary-500">
											We will not
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Install anything that is not related to your service
											when provisioning your VPN server and that includes:
											telemetry, backdoors, data gathering or data processing
											software.
										</dd>

										<dt className="text-normal leading-5 font-normal text-primary-500">
											We will
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Delete the access to your new server immediately after
											it was created by removing the SSH key that was created
											during install. In doing so, we will not be able to access
											your server again.
										</dd>

										<dt className="text-normal leading-5 font-normal text-orange-500">
											We will
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Place google analytics <b>on this website</b> to measure the
											performance of our marketing campaigns and we will ask for
											your permission to do so.
										</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>

					<div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 mt-20" id={"terms"}>
						<div className="bg-white shadow overflow-hidden sm:rounded-lg mx-auto">
							<div className="px-4 py-5 border-b border-gray-200 sm:px-6">
								<h3 className="text-lg leading-6 font-bold text-gray-900">
									Terms of service
								</h3>
								<p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
									The use of our services requires that you agree to our terms
								</p>
							</div>
							<div className="px-4 py-5 sm:p-0">

								<div className="px-8 py-8 text-gray-600">
									<p>
										nologs-vpn.com has no obligation to monitor the Service or any
										user's use thereof or retain the content of any user session.
										However, nologs-vpn.com reserves the right at all times to
										monitor, review, retain and/or disclose any information as
										necessary to satisfy any applicable law, regulation, legal
										process, or governmental request.
									</p>
								</div>
								<dl>
									<div className="sm:grid sm:grid-cols-4 sm:gap-8 sm:px-6 sm:py-5">
										<dt className="text-sm leading-5 font-normal text-primary-500">
											You may not
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Modify, copy, distribute, transmit, display, perform, reproduce,
											publish, license, create derivative works from, transfer, or sell
											any materials, information, software, products or services
											obtained from this web site and Service without the express
											written permission of nologs-vpn.com and/or the rightful
											owner or licensor of such materials, information, software,
											products or services.
										</dd>

										<dt className="text-sm leading-5 font-normal text-primary-500">
											You may not
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Use the Service in any manner which could damage, disable,
											overburden, or impair any nologs-vpn.com Site/Service(s) (or the
											network(s) connected to any nologs-vpn.com Site/Service(s)) or
											interfere with any other party's use and enjoyment of any
											nologs-vpn.com Site/Service(s).
										</dd>

										<dt className="text-sm leading-5 font-normal text-primary-500">
											You may not
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Attempt to gain unauthorized access to any nologs-vpn.com
											Site/Service, other accounts, computer systems or networks
											connected to any nologs-vpn.com Site/Service, through hacking,
											password mining or any other means
										</dd>

										<dt className="text-sm leading-5 font-normal text-primary-500">
											You may not
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Obtain or attempt to obtain any materials or information through
											any means not intentionally made available through the
											nologs-vpn.com Site/Service(s)
										</dd>

										<dt className="text-sm leading-5 font-normal text-primary-500">
											You will
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											Comply with all applicable laws and regulations
											regarding online conduct and content. You will not use the
											Service to collect, store, or use information about others for
											the purpose of sending unsolicited or unauthorized
											communications. If you violate any of these terms and conditions,
											your permission to use the nologs-vpn.com Site/Service(s)
											automatically terminates.
										</dd>

										<dt className="text-sm leading-5 font-normal text-primary-500">
											You may not
										</dt>
										<dd className="mt-1 text-normal leading-6 text-gray-600 sm:mt-0 sm:col-span-3">
											<ul>
												<li className={"mt-2"}>
													Use the Service in connection with surveys, contests,
													pyramid schemes, chain letters, junk e-mail, spamming,
													or any duplicative or unsolicited messages (commercial
													or otherwise);
												</li>
												<li className={"mt-4"}>
													Defame, abuse, harass, stalk, threaten, or otherwise
													violate any of the legal rights of others;
												</li>
												<li className={"mt-4"}>
													Publish, distribute, or disseminate any inappropriate,
													profane, defamatory, infringing, obscene, indecent, or
													unlawful material/information;
												</li>
												<li className={"mt-4"}>
													Advertise or offer to sell or buy any goods or
													services for any non-personal purpose;
												</li>
												<li className={"mt-4"}>
													Harvest or otherwise collect information about others,
													luding e-mail addresses, without their consent;
												</li>
												<li className={"mt-4"}>
													Create a false identity for the purpose of misleading
													others as to the identity of the sender or the origin
													of a message;
												</li>
												<li className={"mt-4"}>
													Transmit or upload any material that contains viruses,
													trojan horses, worms, time bombs, cancelbots, or any
													other harmful or deleterious programs;
												</li>
												<li className={"mt-4"}>
													Transmit or upload any material that contains software
													or other material protected by intellectual property
													laws, rights of privacy or publicity or any other
													applicable law unless you own or control the rights
													thereto or have received all necessary consents;
												</li>
												<li className={"mt-4"}>
													Interfere with or disrupt networks connected to the
													Service or violate the regulations, policies or
													procedures of such networks;
												</li>
												<li className={"mt-4"}>
													Attempt to gain unauthorized access to the Service,
													other accounts, computer systems, or networks connected
													to the Service through password mining or any other
													means;
												</li>
												<li className={"mt-4"}>
													Interfere with another Member's use and enjoyment of
													the Service or another individual's or entity's use and
													enjoyment of similar services;
												</li>
												<li className={"mt-4"}>
													Violate nologs-vpn.com's own copyrights or take any
													part of the service or its written components and
													manipulate or copy it for your own use.
												</li>
											</ul>
										</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>
				</div>

				<div className="max-w-2xl lg:py-24 py-12 mx-auto pb-16 mt-12 sm:pb-0 sm:px-6 lg:max-w-screen-xl">
					<div className="max-w-4xl mx-auto text-center">
						<h2 className="text-3xl leading-9 font-extrabold text-primary-background">
							Unlimited users and zero restrictions
						</h2>
						<p className="mt-4 text-lg leading-7 text-gray-500">
							Your VM is like any other server and it can be upgraded to fit any scenario.
							We install only open source software which has no limitations in place, it is
							upgradable and highly customizable
						</p>
					</div>
					<Features/>
				</div>
			</Wrapper>

			<Wrapper>
				<div className="bg-bg-background mt-20">
					<div className="max-w-2xl mx-auto py-16 sm:py-24 sm:px-6 lg:max-w-screen-xl lg:px-8">
						<Faq/>
					</div>
				</div>

				<Footer/>
			</Wrapper>
		</Layout>
	);
};

export default Legal;
