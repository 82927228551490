import React from 'react';

import tw, {styled,} from 'twin.macro';

import {Title, Subtitle, Indicator} from '@/components/hero';

const Wrapper = styled.div``;
const Right = styled.div`
	${tw`
		relative
		lg:w-1/2
		lg:h-64
		hidden
		lg:block
		px-16
		flex-1/3
	`}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	title: string;
	subtitle: string;
	indicator: string;
	illustration: React.ReactNode;
}

const Hero: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="max-w-2xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				<div className="px-0 sm:px-4 lg:px-0 flex lg:items-center">
					<div className="flex-2/3">
						<Indicator>{props.indicator}</Indicator>
						<Title>{props.title}</Title>
						<Subtitle>{props.subtitle}</Subtitle>
					</div>
					<Right>{props.illustration}</Right>
				</div>
			</div>

		</Wrapper>
	);
};

export default Hero;
