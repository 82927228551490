import React, {SVGProps} from 'react';
import tw, {styled, theme,} from 'twin.macro';

const Legal = (props: SVGProps<SVGSVGElement> & {}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		id="a79c70b2-3a32-4f99-833c-b78d151fe0d8"
		viewBox="0 0 500.75068 706.15573"
		version="1.1"
		{...props}
	>
		<path
			d="M 47.91332,58.33993 A 47.96791,47.96791 0 0 0 0,106.25325 V 658.243 a 47.96778,47.96778 0 0 0 47.91332,47.91272 H 452.83798 A 47.96766,47.96766 0 0 0 500.7507,658.243 V 106.25321 A 47.96778,47.96778 0 0 0 452.83798,58.33993 Z"
			fill={`${theme`colors.primary.400`}`}
			id="path4"/>
		<rect
			x="24.915991"
			y="92.37149"
			width="450.9187"
			height="579.75262"
			fill={`${theme`colors.primary.100`}`}
			id="rect6"/>
		<rect
			x="83.863586"
			y="196.28941"
			width="333.02353"
			height="21.87746"
			fill={`${theme`colors.primary.200`}`}
			id="rect8"/>
		<rect
			x="83.863586"
			y="254.6293"
			width="333.02353"
			height="21.87746"
			fill={`${theme`colors.primary.200`}`}
			id="rect10"/>
		<rect
			x="83.863586"
			y="312.96918"
			width="333.02353"
			height="21.87746"
			fill={`${theme`colors.primary.200`}`}
			id="rect12"/>
		<rect
			x="83.863586"
			y="371.30908"
			width="333.02353"
			height="21.87746"
			fill={`${theme`colors.primary.200`}`}
			id="rect14"/>
		<rect
			x="83.863586"
			y="429.64896"
			width="333.02353"
			height="21.87746"
			fill={`${theme`colors.primary.200`}`}
			id="rect16"/>
		<rect
			x="83.863586"
			y="487.98886"
			width="333.02353"
			height="21.87746"
			fill={`${theme`colors.primary.200`}`}
			id="rect18"/>
		<rect
			x="83.863586"
			y="546.32874"
			width="333.02353"
			height="21.87746"
			fill={`${theme`colors.primary.200`}`}
			id="rect20"/>
		<rect
			x="68.969543"
			y="130.59151"
			width="335.70795"
			height="431.62448"
			transform="rotate(-10.37053)"
			fill={`${theme`colors.primary.300`}`}
			id="rect22"/>
		<rect
			x="112.85591"
			y="207.95816"
			width="247.93524"
			height="16.28772"
			transform="rotate(-10.37053)"
			fill={`${theme`colors.primary.200`}`}
			id="rect24"/>
		<rect
			x="112.8559"
			y="251.39207"
			width="247.93524"
			height="16.28772"
			transform="rotate(-10.37053)"
			fill={`${theme`colors.primary.200`}`}
			id="rect26"/>
		<rect
			x="112.85588"
			y="294.82599"
			width="247.93524"
			height="16.28772"
			transform="rotate(-10.37053)"
			fill={`${theme`colors.primary.200`}`}
			id="rect28"/>
		<rect
			x="112.85587"
			y="338.25989"
			width="247.93524"
			height="16.28772"
			transform="rotate(-10.37053)"
			fill={`${theme`colors.primary.200`}`}
			id="rect30"/>
		<rect
			x="112.854"
			y="381.6925"
			width="247.93408"
			height="16.287643"
			transform="rotate(-10.370392)"
			fill={`${theme`colors.primary.200`}`}
			id="rect32"
			style={{
				strokeWidth: 0.999995
			}}/>
		<rect
			x="112.85588"
			y="425.12772"
			width="247.93524"
			height="16.28772"
			transform="rotate(-10.37053)"
			fill={`${theme`colors.primary.200`}`}
			id="rect34"/>
		<rect
			x="112.8559"
			y="468.56161"
			width="247.93524"
			height="16.28772"
			transform="rotate(-10.37053)"
			fill={`${theme`colors.primary.600`}`}
			id="rect36"/>
		<path
			d="M 333.86573,30.38536 H 294.13027 V 22.25068 A 22.25068,22.25068 0 0 0 271.87959,0 h -43.00848 a 22.25067,22.25067 0 0 0 -22.25065,22.25068 v 8.13468 h -39.73548 a 22.25067,22.25067 0 0 0 -22.25067,22.25068 v 61.61289 H 356.1164 V 52.63604 A 22.25067,22.25067 0 0 0 333.86573,30.38536 Z"
			fill={`${theme`colors.primary.600`}`}
			id="path54"/>
		<path
			d="m 236.11162,629.96493 c 6.5698,-4.49614 13.32206,-9.17218 21.12382,-11.21592 7.11815,-1.86465 15.29169,-1.37138 20.92177,3.84062 4.54356,4.20617 6.46192,10.671 3.09554,16.20474 a 10.29789,10.29789 0 0 1 -6.82246,4.96408 8.21862,8.21862 0 0 1 -7.38872,-2.88661 c -1.7651,-1.95045 -2.82116,-4.83323 -1.88322,-7.40419 0.98627,-2.70345 3.62463,-4.3568 6.21589,-5.27948 a 46.00407,46.00407 0 0 1 9.27017,-1.915 l 12.89241,-1.76494 52.76891,-7.22392 58.76538,-8.04482 a 1.83592,1.83592 0 0 0 1.27334,-2.24265 1.87009,1.87009 0 0 0 -2.24265,-1.27334 l -116.93112,16.00751 c -4.2295,0.579 -8.51865,0.99359 -12.70418,1.84428 a 21.363,21.363 0 0 0 -9.12184,3.7888 c -5.60138,4.43473 -5.20124,12.27858 -0.0466,16.918 5.21814,4.69652 12.78382,3.888 17.36291,-1.29921 a 15.74343,15.74343 0 0 0 1.19385,-19.291 c -4.99863,-7.53725 -14.10711,-10.43294 -22.81019,-9.36395 -10.06518,1.23628 -18.59831,6.89386 -26.77333,12.48856 -1.92477,1.31724 -0.103,4.47836 1.84033,3.14842 z"
			fill={`${theme`colors.primary.600`}`}
			id="path56"/>
	</svg>
);

export default Legal;
