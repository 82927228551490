import React from 'react';
import {Link, GatsbyLinkProps} from 'gatsby';

import tw, {styled} from 'twin.macro';
import Logo from "@/components/illustrations/logo";

const Wrapper = styled.div``;

interface NavLinkProps extends GatsbyLinkProps<any> {
	isActive?: boolean;
}

const NavLink = styled(Link)<NavLinkProps>(
	({isActive}) => [
		tw`ml-8
        inline-flex
        items-center
        px-1
        pt-1
        text-base
        font-bold
        leading-5
        text-white
        hover:text-primary-200
        focus:outline-none
        focus:text-primary-200
        transition
        duration-150
        ease-in-out`,

		isActive && (
			tw`
            text-gray-900
            focus:outline-none`
		)
	]
);

const NavLinkA = styled.a`
	${tw`ml-8
        inline-flex
        items-center
        px-1
        pt-1
        text-base
        font-bold
        leading-5
        text-white
        hover:text-primary-200
        focus:outline-none
        focus:text-primary-200
        transition
        duration-150
        ease-in-out`}
`;

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

const Nav: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="relative">
				<div className="max-w-7xl mx-auto px-4 sm:px-6">
					<div
						className="flex justify-between items-center border-b border-indigo-400 border-opacity-25 py-6 md:justify-start md:space-x-10">
						<div className="lg:flex-1">
							<NavLink to={'/'} style={{marginLeft: 0}}>
								<Logo
									width={160}
									className={"w-full"}
								/>
							</NavLink>
						</div>
						<nav className="hidden md:flex space-x-10">
							<NavLink to={'/#pricing'}>Pricing</NavLink>
							<NavLinkA href={'https://kb.nologs-vpn.com'} target={'__blank'}>
								Knowledgebase
							</NavLinkA>
							<NavLink to={'/contact/'}>Contact</NavLink>
						</nav>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default Nav;
